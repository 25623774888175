// @flow

/**
 * Module dependencies.
 */

import { graphql } from 'gatsby';
import Image from 'gatsby-image';

/**
 * Export `imageFragment`.
 */

export const imageFragment = graphql`
  fragment ResponsiveImage on ImageSharp {
    fluid(
      maxWidth: 1920
      quality: 95
      srcSetBreakpoints: [375, 480, 576, 768, 992, 1200, 1440, 1920]
    ) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
`;

/**
 * Export `Image` component.
 */

export default Image;
